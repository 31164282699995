
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  Ref,
  watchEffect,
} from "vue";
import VideoControl from "@/components/VideoControl.vue";
import { ElMessage } from "element-plus";
import { startFlashPlayer } from "@/electronService";
import store, { MutationNames } from "@/store/index";
import logger from "@evideo/logger";
export default defineComponent({
  components: { VideoControl },

  setup(props) {
    const device = navigator.userAgent;
    const isAndroid = ref(true);
    isAndroid.value =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    let audioVisible = ref(false);
    let imageVisible = ref(false);
    let videoVisible = ref(false);
    let src = ref("");

    // video模块
    const videoRef = ref<HTMLVideoElement>();
    logger.info("videoRef", videoRef);

    // 播放逻辑
    const status: Ref<"play" | "pause" | "stop"> = ref("stop");
    const duration = ref(1);
    const currentTime = ref(0);

    const onPlay = (_status: "play" | "pause" | "stop") => {
      logger.info("onPlay", videoRef, _status);
      const player = videoRef.value;
      if (!player) {
        return;
      }
      if (_status === "play") {
        player.play();
        console.info(`【调试日志】视频播放`);
      } else {
        player.pause();
        console.info(`【调试日志】视频暂停`);
      }
      status.value = _status;
    };
    const onStop = () => {
      const player = videoRef.value;
      status.value = "stop";
      if (!player) {
        return;
      }
      player.currentTime = 0;
      player.pause();
      console.info(`【调试日志】视频停止`);
    };

    const onSetTime = (currentTime: number) => {
      const player = videoRef.value;
      if (!player) {
        return;
      }
      player.currentTime = currentTime;
    };

    const doAction = () => {
      const player = videoRef.value;
      logger.info("video is playing: ", player?.paused);
      if (player) {
        if (player.paused) {
          onPlay("play");
        } else {
          onPlay("pause");
        }
      }
    };
    const init = () => {
      const player = videoRef.value;
      player &&
        player.addEventListener("durationchange", () => {
          duration.value = player.duration;
        });

      player &&
        player.addEventListener("timeupdate", () => {
          currentTime.value = player.currentTime;
        });
    };
    watchEffect(init);
    // 检查是否有flash播放器事件支持
    const checkHasFlashPlayer = () => {
      const list = store.state.registedEvents || [];
      try {
        // windows
        if (
          isAndroid.value === false &&
          (list as any).indexOf("start-flash-player") > -1
        ) {
          return true;
        } else if (
          isAndroid.value == true &&
          (list as any).indexOf("openCourseWare") > -1
        ) {
          // 安卓
          return true;
        }
      } catch (error) {
        return false;
      }

      return false;
    };

    const handleShowResource = (
      type: any,
      visible: any,
      url: any,
      data: any = {}
    ) => {
      logger.info("type", type, visible);

      if (!visible) {
        // 关闭时清空掉更多资源点击的全局变量
        store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
      }

      if (type === "audio") {
        audioVisible.value = visible;
      } else if (type === "image") {
        imageVisible.value = visible;
      } else if (type === "video") {
        videoVisible.value = visible;
        onStop();
      } else if (type === "swf") {
        if (checkHasFlashPlayer() == false) {
          ElMessage.error({
            offset: 200,
            message: "该系统版本暂不支持预览本类型，请升级系统",
            type: "error",
          });
          return;
        }

        if (!isAndroid.value) {
          try {
            startFlashPlayer(data);
          } catch (err) {
            console.error(err);
            ElMessage.error({
              offset: 200,
              message: "打开课件异常，请重试",
              type: "error",
            });
          }
          return;
        } else {
          if (!window.WebViewJavascriptBridge) {
            logger.info(" window.WebViewJavascriptBridge is null");
            return;
          }
          try {
            window.WebViewJavascriptBridge.callHandler(
              "openCourseWare",
              JSON.stringify(data)
            );
          } catch (err) {
            console.error(err);
            ElMessage.error({
              offset: 200,
              message: "打开课件异常，请重试",
              type: "error",
            });
          }
          return;
        }
      }
      src.value = url;
    };
    const reload = () => {
      audioVisible.value = false;
      imageVisible.value = false;
      videoVisible.value = false;
      src.value = "";
    };

    return {
      videoRef,
      audioVisible,
      imageVisible,
      videoVisible,
      src,
      duration,
      status,
      currentTime,
      onPlay,
      doAction,
      onSetTime,
      onStop,
      handleShowResource,
      reload,
    };
  },
});
