// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/myCourseWare/courseWare/close-pre.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/img/myCourseWare/courseWare/close.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/img/myCourseWare/courseWare/video-close-btn-hl.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("@/assets/img/myCourseWare/courseWare/video-close-btn.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "video[data-v-4caabcaa]::-webkit-media-controls-fullscreen-button,video[data-v-4caabcaa]::-webkit-media-controls-timeline{display:none}audio[data-v-4caabcaa]::-webkit-media-controls-volume-control-container,video[data-v-4caabcaa]::-webkit-media-controls-volume-control-container{display:none}audio[data-v-4caabcaa]::-webkit-media-controls-play-button{background-size:150%;margin-right:10px}audio[data-v-4caabcaa]::-webkit-media-controls-current-time-display,audio[data-v-4caabcaa]::-webkit-media-controls-time-remaining-display{font-size:18px}.bg[data-v-4caabcaa]{z-index:509;position:fixed;left:0;top:0;width:100%;height:100%;background:rgba(0,0,0,.4)}.bg[data-v-4caabcaa],.bg .audio-bg[data-v-4caabcaa]{display:flex;justify-content:center;align-items:center}.bg .audio-bg[data-v-4caabcaa]{width:38%;height:12%;background-color:#f1f3f4;border-radius:1.5vw;position:relative;min-height:54px}.bg .audio-bg audio[data-v-4caabcaa]{width:86%}.bg .audio-bg .close[data-v-4caabcaa]{width:32px;height:32px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.bg .audio-bg .close[data-v-4caabcaa]:active{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.bg .video-bg[data-v-4caabcaa]{border-radius:1.5vw;background-color:transparent;width:100vw;height:100vh}.bg .video-bg video[data-v-4caabcaa]{width:100vw;height:100vh;-o-object-fit:fill;object-fit:fill}.bg .video-bg .video-control[data-v-4caabcaa]{width:50%;height:72px;position:absolute;left:0;right:0;margin-left:auto;margin-right:auto;bottom:10px;z-index:12}.bg .video-bg .close[data-v-4caabcaa]{position:fixed;bottom:10px;right:0;z-index:1;width:117px;height:61px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.bg .video-bg .close[data-v-4caabcaa]:active{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.bg .img-bg[data-v-4caabcaa]{width:100%;height:100%;display:flex;align-items:center;justify-content:center}", ""]);
// Exports
module.exports = exports;
