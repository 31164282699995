
import {
  defineComponent,
  onBeforeMount,
  onMounted,
  reactive,
  ref,
  onActivated,
  toRefs,
  toRaw,
  onBeforeUnmount,
  watch,
  computed,
  inject,
} from "vue";
import store, { MutationNames } from "@/store/index";
import { ElMessage } from "element-plus";
import { onBeforeRouteLeave, useRouter } from "vue-router";
import HomeBackground from "@/components/common/HomeBackground";
import {
  getTeacherInfo,
  getCoursewares,
  getBooks,
  getSections,
  addViewLog,
  addDownloadLog,
  copyOfficial,
  addInsViewLog,
  getInstitutionCoursewares,
  getSchoolCoursewares,
  getSchoolInstitutionInfo,
  dataEmbeddingPoint,
} from "@/services";
import {
  eventCenter,
  event,
  usePageTableContext,
} from "@evideo/frontend-utils";
import servers from "@/config/servers";
import config from "@/config/app.config";
import CustomAudio from "@/components/CustomAudio.vue";
import CustomPpt from "@/components/CustomPPT.vue";
import ShowResource from "@/components/showResource";
import _ from "lodash";
import { startFlashPlayer } from "@/electronService";
import pageTableContextMap from "@/utils/page-table-context-map";
import moment from "moment";
import remindEmptyResource from "@/components/remindEmptyResource.vue";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper.min.css";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import logger from "@evideo/logger";
import {
  getCacheFile,
  uploadCacheFile,
  uploadCacheFileFormData,
} from "@/services/localResource";
import LocalResourceExchange from "@evideo/local-resource-exchange";
import { SocketEventMap } from "@/utils/socket-event";
import {
  checkCurrentCacheDownloading,
  subCurrentCacheDownloadNum,
} from "@/utils/localResourceParse";

export default defineComponent({
  components: {
    CustomAudio,
    CustomPpt,
    ShowResource,
    HomeBackground,
    remindEmptyResource,
    Swiper,
    SwiperSlide,
  },
  setup() {
    let CoursewaresId = ref("");
    let refreshItem = ref(0);
    let musicUrl = ref("");
    let loading = ref(false);
    let isAndroid = ref(true);
    let firstLoading = ref(true);
    let mySwiper = ref(null);
    const showResourceRef = ref(null);
    const router = useRouter();
    let teacherName = ref(store.state.nickName);
    let schoolName = ref(store.state.schoolName);
    // 当前页
    let currentPage = ref(1);
    // 总页数
    let totalPage = ref(1);
    let message = ref("");
    let searchName = ref("");
    let imgVisible = ref(false);
    let audioVisible = ref(false);
    let videoVisible = ref(false);
    let activePanelActive = ref(false);
    let audioSrc = ref("");
    let cascaderList = ref([]);
    let teachResourceEight = reactive([]);
    let teachResourceList = reactive([]);
    let middleTeachResourceEight = reactive([]);
    let lastTeachResourceEight = reactive([]);
    let leftBorderFlag = ref(true);
    let rightBorderFlag = ref(true);
    let videoSrc = ref("");
    let imgSrc = ref("");
    let pptUrl = ref("");
    let firstLoad = ref(false);
    let noData = ref(false);
    let noDataMsg = ref(false);
    // 点击缓存的节点数据
    let DownloadingData = ref({});
    // 是否启用缓存模块
    const localResourceState = ref(store.state.localResourceState);
    // 是否劢联定制版
    let isML = ref(false);
    // 翻页状态 翻页中不接受缓存返回修改数据 true|翻页钟不可以修改 false|翻页结束可以修改
    let TurningPagesState = ref(false);
    // 翻页数据加载状态（处理网络环境差的情况），当翻页后的数据未加载完成，不能进行翻页操作 true|能翻页 false|不能翻页
    // let TurningPagesDataState = ref(true);

    const isHighVersion = computed(() => {
      return _.gte(store.state.version, "1.4.3");
    });
    // 判断是否是新版本，走新缓存
    const currentPosition = ref("platform");
    // 当前所处位置，平台，区域，学校

    const typeOptions = [
      {
        value: "ppt",
        label: "ppt",
      },
      {
        value: "image",
        label: "图片",
      },
      {
        value: "video",
        label: "视频",
      },
      {
        value: "audio",
        label: "音频",
      },
      {
        value: "swf",
        label: "flash",
      },
      // {
      //   value: "pdf",
      //   label: "pdf",
      // },
    ];
    const socket = inject("socket");

    const downloadStatus = (res) => {
      console.log("#message: ", res, TurningPagesState.value);
      const { cloud_id, type, status } = res.data.details[0] || {};
      if (!TurningPagesState.value) {
        if (res.data.details[0].mode === "moreEight") {
          // console.log("resourcesEight1.value", teachResourceEight);
          teachResourceEight.forEach((item) => {
            // console.log("item1", item, TurningPagesState.value);
            if (
              item.url !== "" &&
              (decodeURI(item.url) === res.data.details[0].cloud_url ||
                (cloud_id === item.id && item?.type?.toUpperCase() === type))
            ) {
              // console.log("ittt1", item, TurningPagesState.value);
              if (res.data.details[0].status === 0) {
                item.localState = "success";
                item.url = res.data.details[0].file_url;
              } else {
                item.localState = "error";
                item.localDownloadProgress = undefined;
                ElMessage.error({
                  offset: 200,
                  message: "资源下载失败",
                  type: "error",
                });
              }
            }
          });
        } else if (res.data.details[0].mode === "moreMiddle") {
          // console.log("resourcesEight2.value", middleTeachResourceEight);
          middleTeachResourceEight.forEach((item) => {
            // console.log("item2", item, TurningPagesState.value);
            if (
              item.url !== "" &&
              (decodeURI(item.url) === res.data.details[0].cloud_url ||
                (cloud_id === item.id && item?.type?.toUpperCase() === type))
            ) {
              // console.log("item21", item, TurningPagesState.value);
              if (status === 0) {
                item.localState = "success";
                item.url = res.data.details[0].file_url;
              } else {
                item.localState = "error";
                item.localDownloadProgress = undefined;
                ElMessage.error({
                  offset: 200,
                  message: "资源下载失败",
                  type: "error",
                });
              }
            }
          });
        } else if (res.data.details[0].mode === "moreLast") {
          // console.log("resourcesEight3.value", lastTeachResourceEight);
          lastTeachResourceEight.forEach((item) => {
            // console.log("item3", item, TurningPagesState.value);
            if (
              item.url !== "" &&
              (decodeURI(item.url) === res.data.details[0].cloud_url ||
                (cloud_id === item.id && item?.type?.toUpperCase() === type))
            ) {
              // console.log("item31", item, TurningPagesState.value);
              if (status === 0) {
                item.localState = "success";
                item.url = res.data.details[0].file_url;
              } else {
                item.localState = "error";
                item.localDownloadProgress = undefined;
                ElMessage.error({
                  offset: 200,
                  message: "资源下载失败",
                  type: "error",
                });
              }
            }
          });
        }
      } else {
        logger.info("翻页中不接受缓存数据修改");
      }
    };
    const downloadProgress = (res) => {
      console.log("#message progress: ", res);
      if (!isHighVersion.value) {
        return;
      }
      const { mode, complete_file_num, total_file_num, cloud_id, type } =
        res.data || {};
      const currentProgress = total_file_num
        ? Math.floor((complete_file_num / total_file_num) * 100)
        : 0;
      if (mode === "moreEight") {
        teachResourceEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreMiddle") {
        middleTeachResourceEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      } else if (mode === "moreLast") {
        lastTeachResourceEight.forEach((item) => {
          if (item?.id === cloud_id && item?.type?.toUpperCase() === type) {
            item.localDownloadProgress = currentProgress;
          }
        });
      }
    };
    socket.on(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
    socket.on(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    let typeValue = ref("");
    const device = navigator.userAgent;
    const android =
      device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
    if (android) {
      isAndroid.value = false;
    }
    const CascaderRef = ref();
    const state = reactive({
      props: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level } = node;
          let dataInfo = [];
          let nodes = "";
          if (level === 0) {
            getBooks({ per_page: 1000, show_modules: 1 }).then((data) => {
              dataInfo.push(...data.data.items);
              level + 1;
              nodes = dataInfo.map((item) => ({
                value: item.id,
                label: item.name,
                leaf: level >= 1,
              }));
              resolve(nodes);
            });
          } else {
            getSections(node.value).then((data) => {
              dataInfo.push(...data.data);
              logger.info("dataInfo", dataInfo);
              level + 1;
              if (dataInfo.length > 0) {
                nodes = dataInfo.map((item) => ({
                  value: item.id,
                  label: item.name,
                  leaf: level >= 1,
                }));
              } else {
                nodes = undefined;
              }

              logger.info("nodes", nodes);
              resolve(nodes);
            });
          }
        },
      },
    });
    // // 屏幕滑动翻页
    // let startX = ref(0); // 触摸起始x
    // let startY = ref(0); // 触摸起始y
    // let endX = ref(0); // 触摸结束x
    // let endY = ref(0); // 触摸结束y
    // let canMove = ref(true); // 是否可以继续滑动
    // let index = ref(0); // 控制滑动下标

    // tab栏的值
    const TabTypeMap = {
      School: "school",
      Institution: "institution",
      Platform: "platform",
    };
    // 当前tab栏
    const currentTab = ref(TabTypeMap.Platform);

    // 是否展示区域课件
    const showIns = ref(false);

    // 学校id
    const schoolId = ref();

    // 级联选择书本的完整路径
    const bookSelectFullValue = ref();

    // 获取检索表单的参数上下文工具
    const { getContextInfo, saveContextInfo, deleteContextInfo } =
      usePageTableContext();

    const handleGetPageContext = () => {
      const context = getContextInfo(pageTableContextMap.COURSEWARE_CENTER);
      logger.info(context);
      const {
        currentTab: cTab,
        CoursewaresId: cId,
        typeValue: tValue,
        currentPage: cPage,
        searchName: sname,
        bookSelectFullValue: bsfValue,
      } = context;
      if (cTab) {
        currentTab.value = cTab;
      }
      if (cId) {
        CoursewaresId.value = cId;
      }
      if (tValue) {
        typeValue.value = tValue;
      }
      if (sname) {
        searchName.value = sname;
      }
      if (bsfValue) {
        bookSelectFullValue.value = bsfValue;
      }
      if (cPage && cPage > 1) {
        currentPage.value = cPage;
      }

      deleteContextInfo(pageTableContextMap.COURSEWARE_CENTER);
    };

    const handleSavePageContext = () => {
      const pageContext = {
        // 当前课件是平台/区域/还是学校
        currentTab: currentTab.value,
        // 当前课件所属教材筛选项
        CoursewaresId: CoursewaresId.value,
        // 当前课件类型筛选
        typeValue: typeValue.value,
        currentPage: currentPage.value,
        searchName: searchName.value,
        bookSelectFullValue: toRaw(bookSelectFullValue.value),
      };
      saveContextInfo(pageTableContextMap.COURSEWARE_CENTER, pageContext);
    };

    const handleGetInsInfoBySchoolId = async () => {
      getSchoolInstitutionInfo(schoolId.value).then(({ data }) => {
        logger.info("data", data);
        if (data && data.uplevel_ins_data && data.uplevel_ins_data.length > 0) {
          showIns.value = true;
        }
      });
    };
    if (window.require) {
      const { ipcRenderer } = window.require("electron");
      ipcRenderer.on("coursewareCenterGoBack", (event, message) => {
        // 接收electron原生返回的信息
        logger.info("我接收到的electron发过来的信息", event, message);
        // handleGoMusicKnowLedgeHome();
        if (activePanelActive.value) {
          activePanelActive.value = false;
          showResourceRef.value.handleShowResource("video", false);
        } else {
          router.push("/home");
        }
      });
    }
    onBeforeMount(() => {
      isML.value = store.state.isML;
      const device = navigator.userAgent;
      const android =
        device.indexOf("Android") > -1 || device.indexOf("Adr") > -1;
      if (android) {
        isAndroid.value = false;
      }
      // 初始化上次保存的检索参数
      handleGetPageContext();
      getTeacherdata();
    });
    onMounted(() => {
      logger.info("当前tab值是===>", currentTab.value);
      logger.info("当前currentPage值是===>", currentPage.value);
      logger.info("当前totalPage值是===>", totalPage.value);
      // currentPage.value = 1;
      init();
      const param = {
        book_section_id: CoursewaresId.value || undefined,
        name: searchName.value || undefined,
        type: typeValue.value || undefined,
      };
      logger.info("param====>", param);
      initGetResourceInfo(param);
      firstLoading.value = false;
      // border();
      // getResourceInfo(param);
      getTeacherData();
      handleGetInsInfoBySchoolId();
      // 预加载ppt
      // if (store.state.firstLoadPPT) {
      //   // console.time("开始");
      //   loading.value = true;
      //   loadPPT();
      //   store.commit(MutationNames.CHANGE_FIRSTLOADPPT, false);
      // }
      // 接收上级应用 预加载完成，可以卸载应用了
      // eventCenter.sub("teachPptPC-preloadOk", () => {
      //   // console.timeEnd("开始");
      //   eventCenter.send(event.COMMON_UNLOAD_MICRO_APP, { app: "teachPptPC" });
      //   loading.value = false;
      // });
    });
    onActivated(() => {
      logger.info("firstLoadPPT", store.state.firstLoadPPT);
      if (android) {
        logger.info("通知android显示");
        window.WebViewJavascriptBridge.callHandler(
          "loadSuccess", // native中注册时的key值
          null // native中注册方法中的传入的数据
        );
      }
      if (firstLoad.value) {
        refreshItem.value++;
        searchName.value = "";
        currentPage.value = 1;
        CoursewaresId.value = "";
        showResourceRef.value.reload();
        getResourceInfo();
        mySwiper.value.activeIndex = 1;
      }
    });
    onBeforeRouteLeave((to, from, next) => {
      if (to.name === "Home") {
        firstLoad.value = true;
      }
      next();
    });
    onBeforeUnmount(() => {
      if (window.require) {
        const { ipcRenderer } = window.require("electron");
        ipcRenderer.removeAllListeners("coursewareCenterGoBack");
      }
      socket.off(SocketEventMap.DOWNLOAD_STATUS, downloadStatus);
      socket.off(SocketEventMap.DOWNLOAD_PROGRESS, downloadProgress);
    });
    // tab栏修改
    const handleTabClick = (type) => {
      // leftBorderFlag.value = true;
      // rightBorderFlag.value = true;
      currentPosition.value = type;
      // 绑定课件来源
      if (currentTab.value !== type) {
        currentTab.value = type;
        const params = {
          book_section_id: CoursewaresId.value || undefined,
          name: searchName.value || undefined,
          type: typeValue.value || undefined,
        };
        currentPage.value = 1;
        getResourceInfo(params);
        mySwiper.value.activeIndex = 1;
      }
    };
    const getResourceFuncByTabType = () => {
      let func = undefined;
      switch (currentTab.value) {
        case TabTypeMap.Platform:
          func = getCoursewares;
          break;
        case TabTypeMap.Institution:
          func = getInstitutionCoursewares;
          break;
        case TabTypeMap.School:
          func = getSchoolCoursewares;
          break;
        default:
          func = getCoursewares;
          break;
      }
      return func;
    };

    const getTeacherdata = () => {
      getTeacherInfo({}).then((data) => {
        logger.info("看看课件中心的data", data);
        teacherName.value = data.nick_name;
        schoolName.value = data.school_name;
        schoolId.value = data.school_id;
        store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
        store.commit(MutationNames.CHANGE_SCHOOLNAME, data.school_name);
      });
    };
    // 关闭资源预览
    const handleCloseShow = (type) => {
      if (type === "audio") {
        audioVisible.value = false;
        audioSrc.value = "";
      } else if (type === "video") {
        videoVisible.value = false;
        videoSrc.value = "";
      } else if (type === "img") {
        imgVisible.value = false;
        imgSrc.value = "";
      }
    };
    // 资源类型
    const bindIcon = (icon) => {
      return require("@/assets/img/myCourseWare/courseWare/" + icon + ".png");
    };
    const loadPPT = async () => {
      logger.info("预加载");
      if (!window.qiankunStarted) {
        window.qiankunStarted = true;
      }
      const env = JSON.stringify(process.env.RELEASE_ENV);
      // let entryUrl = "http://192.168.74.175:8090/";
      let entryUrl = config[servers.pptist];
      if (env === `"devp"` && localStorage.getItem("entryUrl") !== null) {
        entryUrl = localStorage.entryUrl;
      }
      eventCenter.send(event.COMMON_LOAD_MICRO_APP, {
        app: "teachPptPC",
        el: "#ppt-preload",
        // el: "#courseware-center-ppt-preload",
        entry: entryUrl,
        args: {
          token: store.state.token,
          appName: "teachPptPC",
          preload: true,
        },
        configuration: { $$cacheLifecycleByAppName: true },
      });
    };

    const getTeacherData = () => {
      getTeacherInfo({}).then((data) => {
        logger.info("teacherName", data);
        teacherName.value = data.nick_name;
        store.commit(MutationNames.CHANGE_NICKNAME, data.nick_name);
      });
    };
    // 切换课件类型时重新请求课件列表
    const getResourceInfo = (params) => {
      mySwiper.value.touches.diff = 0;
      teachResourceEight.length = 0;
      middleTeachResourceEight.length = 0;
      lastTeachResourceEight.length = 0;
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 1,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        if (finishedData.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
        teachResourceEight.length = 0;
        teachResourceEight.push(...finishedData.data.items);
        logger.info("初始化拿数据data===>", teachResourceEight);
        // 选择类型或搜索之后判断边界值
        if (currentPage.value == 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if ((currentPage.value == totalPage.value) == 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        logger.info(
          "我在函数里判断完了",
          currentPage.value,
          totalPage.value,
          leftBorderFlag.value,
          rightBorderFlag.value
        );
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 2,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        middleTeachResourceEight.length = 0;
        middleTeachResourceEight.push(...finishedData.data.items);
        logger.info("初始化拿数据data===>", middleTeachResourceEight);
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: 3,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        lastTeachResourceEight.length = 0;
        lastTeachResourceEight.push(...finishedData.data.items);
        logger.info("初始化拿数据data===>", lastTeachResourceEight);
      });
      // searchFunc({
      //   is_primary: 0,
      //   per_page: 8,
      //   page: currentPage.value,
      //   book_show_modules: 1,
      //   ...params,
      // }).then((data) => {
      //   teachResourceEight.length = 0;
      //   teachResourceList.length = 0;
      //   totalPage.value = data.data.meta.last_page;
      //   if (data.data.items.length === 0) {
      //     noData.value = false;
      //   } else {
      //     noData.value = true;
      //   }
      //   teachResourceEight.push(...data.data.items);
      // });
    };
    // 下载资源到本地,修改更多资源的数据
    const handleLocalUpdateMoreData = (type, updateObj) => {
      let data = [];
      if (type === "eight") {
        data = teachResourceEight;
      } else if (type === "last") {
        data = lastTeachResourceEight;
      } else {
        data = middleTeachResourceEight;
      }
      let params = [];
      data.forEach((el) => {
        let newNode = el;
        if (newNode.id === DownloadingData.value.id) {
          // newNode["localState"] = "downloading";
          for (let key in updateObj) {
            newNode[key] = updateObj[key];
          }
        }
        // console.log("newNode前", newNode);
        newNode = JSON.parse(JSON.stringify(newNode));
        // console.log("newNode后", newNode);
        params.push(newNode);
      });
      return params;
    };
    // 将图标变为未下载状态
    const handleChangeLoadingOther = (data, targetItem) => {
      data.forEach((item) => {
        if (item.id === targetItem.id) {
          item["localState"] = "error";
        }
      });
      return data;
    };
    // 判断当前下载文件在哪部分，并执行对应函数
    const handleChooseCatchOrigin = (type, data) => {
      if (type === "moreEight") {
        teachResourceEight.value = handleChangeLoadingOther(
          teachResourceEight,
          data
        );
      } else if (type === "moreLast") {
        lastTeachResourceEight.value = handleChangeLoadingOther(
          lastTeachResourceEight,
          data
        );
      } else {
        middleTeachResourceEight = handleChangeLoadingOther(
          middleTeachResourceEight,
          data
        );
      }
    };

    const handleUpdateData = (type, data, updateObj) => {
      // type 区分是更多资源触发还是章节列表触发 tree|章节列表 more|更多资源
      if (type === "moreEight") {
        const newData = handleLocalUpdateMoreData("eight", updateObj);
        console.log("修改更多第一页数据", newData);
        teachResourceEight.length = 0;
        teachResourceEight.push(...newData);
      } else if (type === "moreLast") {
        const newData = handleLocalUpdateMoreData("last", updateObj);
        console.log("修改更多最后页数据", newData);
        lastTeachResourceEight.length = 0;
        lastTeachResourceEight.push(...newData);
      } else {
        const newData = handleLocalUpdateMoreData("middle", updateObj);
        console.log("修改更多中间页数据", newData);
        middleTeachResourceEight.length = 0;
        middleTeachResourceEight.push(...newData);
      }
    };

    // 处理该教材需要确认的资源数据
    const handleLocalResource = (data, type) => {
      if (isHighVersion.value) {
        if (!checkCurrentCacheDownloading()) {
          return;
        }
        const analyzeData = {
          type: data.type,
          url: data.url,
          name: data.name,
          version_time: data.updated_at,
          source: handleChangeResource(currentPosition.value),
          cloud_id: data.id,
        };
        window.requestIdleCallback(() => {
          LocalResourceExchange.resourceParse(analyzeData)
            .then((res) => {
              let formData = new FormData();
              formData.append("version_time", data.updated_at);
              formData.append("mode", type);
              formData.append("cloud_id", data.id);
              if (data.type == "ppt") {
                formData.append("resource_file", res);
                formData.append("name", data.name + ".json");
              } else {
                formData.append("resource_file_url", data.url);
                formData.append(
                  "name",
                  data.name +
                    "." +
                    data.url.substring(data.url.lastIndexOf(".") + 1)
                );
              }
              formData.append("type", data.type.toUpperCase());
              formData.append(
                "source",
                handleChangeResource(currentPosition.value)
              );
              uploadCacheFileFormData(formData)
                .then(() => {
                  handleUpdateData(type, data, { localDownloadProgress: 0 });
                  console.log("创建缓存资源下载成功");
                })
                .catch((err) => {
                  console.error(err);
                  handleChooseCatchOrigin(type, data);
                  ElMessage.error({
                    offset: 200,
                    message: "资源下载失败",
                    type: "error",
                  });
                });
            })
            .catch((err) => {
              console.error(err);
              handleChooseCatchOrigin(type, data);
              ElMessage.error({
                offset: 200,
                message: "资源下载失败",
                type: "error",
              });
            })
            .finally(() => {
              subCurrentCacheDownloadNum();
            });
        });
      } else {
        const params = [
          {
            url: data.url,
            version_time: data.updated_at,
            md5: data.md5,
            id: data.id,
            name: data.name,
            mode: type,
          },
        ];
        uploadCacheFile(params);
      }

      console.log("下载到本地", data, type);
      DownloadingData.value = data;
      handleUpdateData(type, data, { localState: "downloading" });
    };
    // 向缓存服务确认资源是否在本地已存在
    const isLocalCacheData = async (data) => {
      try {
        const LocalCacheData = await getCacheFile(JSON.stringify(data));
        console.log("testaa", LocalCacheData);
        return LocalCacheData;
      } catch (error) {
        console.log("erroraa", error);
      }
    };
    // 更多资源获取本地是否有缓存资源
    const getLocalResourceState = async (data) => {
      const res = data;
      console.log("获取更多资源的数据data", data.data.items);
      let moreResource = [];
      if (isHighVersion.value) {
        data.data.items.forEach((el) => {
          const newNode = {
            cloud_id: el.id,
            type: el.type.toUpperCase(),
            version_time: el.updated_at,
            source: handleChangeResource(currentPosition.value),
          };
          moreResource.push(newNode);
        });
      } else {
        data.data.items.forEach((el) => {
          const newNode = {
            id: el.id,
            md5: el.md5,
            version_time: el.updated_at,
            url: el.url,
          };
          if (newNode.url !== "") {
            moreResource.push(newNode);
          }
        });
      }

      console.log("获取更多资源的数据moreResource", moreResource);

      // // 将moreResource发送给缓存服务换回数据 在数据里增加localState
      if (moreResource.length > 0) {
        let localMoreResource = [];
        if (isHighVersion.value) {
          moreResource = { items: moreResource };
        }
        try {
          localMoreResource = await isLocalCacheData(moreResource);
        } catch (error) {
          console.log("error", error);
        }

        console.log("resaa", localMoreResource);
        // 缓存服务返回的数据正确则进行数据拼接
        if (localMoreResource != undefined) {
          // 将缓存服务返回的数据拼回传入的数据并且返回回去
          res.data.items.forEach((el, index) => {
            res.data.items[index].localState = "error";
            res.data.items[index].localDownloadProgress = undefined;
            localMoreResource.data.find((data) => {
              let highVersion = false;
              // 对于高版本来说，本地检查返回的是cloud_id
              if (isHighVersion.value) {
                highVersion = el.id === data.cloud_id;
              }
              if (el.id === data.id || highVersion) {
                const { status, file_url } = data.file_info || {};
                if (status) {
                  // 地址不为空才使用
                  if (file_url !== "") {
                    res.data.items[index].url = file_url;
                  }

                  if (status === "success" || status === "loading") {
                    res.data.items[index].localState = status;
                  }

                  // 获取缓存下载进度
                  if (status === "loading") {
                    const { complete_file_num, total_file_num } =
                      data.progress || {};
                    const currentProgress = total_file_num
                      ? Math.floor((complete_file_num / total_file_num) * 100)
                      : 0;
                    res.data.items[index].localDownloadProgress =
                      currentProgress;
                  } else {
                    res.data.items[index].localDownloadProgress = undefined;
                  }
                  return true;
                }
              }
            });
          });
        }
      }

      return res;
    };
    const initGetResourceInfo = async (params) => {
      logger.info("初始化拿数据函数里的当前页====>", currentPage.value);
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        logger.info("data", data.data.items);
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        console.log("finishedData", finishedData);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if (currentPage.value % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...finishedData.data.items);
          mySwiper.value.activeIndex = 1;
        }
        if (currentPage.value % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...finishedData.data.items);
          mySwiper.value.activeIndex = 2;
        }
        if (currentPage.value % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...finishedData.data.items);
          mySwiper.value.activeIndex = 3;
        }
        // 判断结束
        logger.info(
          "这边初始化数据结束，开始判断边界值===>",
          currentPage.value,
          totalPage.value
        );
        if (currentPage.value === 1 && totalPage.value !== 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value === 1
        ) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = false;
        } else if (
          currentPage.value === totalPage.value &&
          totalPage.value !== 1
        ) {
          leftBorderFlag.value = true;
          rightBorderFlag.value = false;
        } else {
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        if (finishedData.data.items.length === 0) {
          noData.value = false;
          noDataMsg.value = true;
        } else {
          noData.value = true;
        }
        // teachResourceEight.push(...data.data.items);
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value + 1,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value + 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...finishedData.data.items);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...finishedData.data.items);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...finishedData.data.items);
        }
        // 判断结束
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        // middleTeachResourceEight.push(...data.data.items);
      });
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: currentPage.value - 1,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        logger.info("data", data.data.items.length);
        logger.info("teachResourceEight.length", teachResourceEight.length);
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        totalPage.value = data.data.meta.last_page;
        // 在这里判断数据放到哪一个窗口
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...finishedData.data.items);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...finishedData.data.items);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...finishedData.data.items);
        }
        // 判断结束
        // if (data.data.items.length === 0) {
        //   noData.value = false;
        // } else {
        //   noData.value = true;
        // }
        // lastTeachResourceEight.push(...data.data.items);
      });
    };
    // // 上一页
    // const handlePrevious = () => {
    //   if (currentPage.value === 1) {
    //     message.value = "已经是第一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     currentPage.value--;
    //     teachResourceEight.length = 0;
    //     const params = {
    //       book_section_id: CoursewaresId.value,
    //       name: searchName.value,
    //       type: typeValue.value,
    //     };
    //     getResourceInfo(params);
    //   }
    // };
    // // 下一页
    // const handleNext = () => {
    //   if (currentPage.value === totalPage.value) {
    //     message.value = "已经是最后一页";
    //     ElMessage.warning({
    //       offset: 200,
    //       message: message.value,
    //       type: "warning",
    //     });
    //   } else {
    //     currentPage.value++;
    //     teachResourceEight.length = 0;
    //     const params = {
    //       book_section_id: CoursewaresId.value,
    //       name: searchName.value,
    //       type: typeValue.value,
    //     };
    //     getResourceInfo(params);
    //   }
    // };
    const handleCopyOfficial = (e) => {
      const params = { official_courseware_id: e.id };
      copyOfficial(params).then((data) => {
        logger.info("copyOfficial", data);
        if (data.message === "success") {
          addDownloadLog(e.id);
          ElMessage.success({
            offset: 200,
            message: "获取成功，已添加至“我的课件”",
            type: "success",
          });
        }
      });
    };
    // 数据埋点
    const handleDataReport = async (item) => {
      let ownerType = "";
      if (currentTab.value === "platform") {
        ownerType = "official";
      } else if (currentTab.value === "institution") {
        ownerType = "institution";
      } else {
        ownerType = "school";
      }
      const eventattr = { id: item.id, owner_type: ownerType };
      logger.info("eventattr", eventattr);
      const ca = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
      if (isAndroid.value) {
        // pc数据上报
        const data = {
          uid: store.state.pcUserIdAES,
          ct: "96",
          ci: store.state.mac,
          mac: store.state.mac,
          sv: store.state.version,
          fv: store.state.hardwareModel,
          ca: ca,
          pt: "pc",
          eventid: "teaching_coursewarecenter_open",
          pageid: "coursewarecenter",
          moduleid: "teaching",
          eventattr: eventattr,
        };
        logger.info("data", data);
        const body = encodeURIComponent(JSON.stringify(data));
        logger.info("body", body);
        await dataEmbeddingPoint(body);
      } else {
        // android数据上报
        window.WebViewJavascriptBridge.callHandler(
          "getDeviceInfo", // native中注册时的key值
          null, // native中注册方法中的传入的数据
          async function (responseData) {
            // 这里打印的应该是上面Handler实现方法中的callback的入参
            logger.info("安卓调用完成", responseData);
            const data = JSON.parse(responseData);
            data.ca = ca;
            data.pt = "android";
            data.eventid = "teaching_coursewarecenter_open";
            data.pageid = "coursewarecenter";
            data.moduleid = "teaching";
            data.eventattr = eventattr;
            const body = encodeURIComponent(JSON.stringify(data));
            logger.info("body", body);
            await dataEmbeddingPoint(body);
          }
        );
      }
    };
    // 卡片点击事件
    const handleCardClick = async (e) => {
      await handleDataReport(e);
      let addViewFunc = undefined;
      if (currentTab.value === TabTypeMap.Platform) {
        addViewFunc = addViewLog;
      } else {
        addViewFunc = addInsViewLog;
      }
      addViewFunc(e.id).then(() => {
        const params = {
          book_section_id: CoursewaresId.value,
          name: searchName.value,
          type: typeValue.value,
        };
        // getResourceInfo(params);
        initGetResourceInfo(params);
      });

      if (e.type === "ppt") {
        handleSavePageContext();
        resetMinimizeState();
        goToMaterialPpt(e.url);
      } else if (e.type === "swf") {
        handleStartFlashPlayer(e);
      } else {
        showResourceRef.value.handleShowResource(e.type, true, e.url);
      }
      if (e.type === "video") {
        activePanelActive.value = true;
      }
    };
    // 快捷跳转状态重置
    const resetMinimizeState = () => {
      store.commit(MutationNames.CHANGE_MINIMIZEBTNSTATE, "false");
      store.commit(MutationNames.CHANGE_MINIMIZEURL, "");
      store.commit(MutationNames.CHANGE_MINIMIZEPPTINFO, "");
      store.commit(MutationNames.CHANGE_PPTTREEID, "");
      store.commit(MutationNames.CHANGE_MORERESOURCEDATA, "");
      store.commit(MutationNames.CHANGE_MORERESOURCECLICKDATA, "");
      store.commit(MutationNames.CHANGE_MOREPPTINFO, "");
      store.commit(MutationNames.CHANGE_MOREPPTSHOW, false);
    };
    const goToMaterialPpt = (pptUrl) => {
      firstLoad.value = false;
      router.push({
        path: "/courseWarePpt",
        query: {
          pptUrl,
          goBackUrl: "coursewareCenter",
        },
      });
    };
    const handleSearch = _.debounce(() => {
      logger.info("search", searchName.value);
      currentPage.value = 1;
      const params = {
        name: searchName.value,
        book_section_id: CoursewaresId.value,
        type: typeValue.value,
      };
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
    }, 500);
    const handleGoToLogin = () => {
      firstLoad.value = true;
      refreshItem.value = 0;
      CoursewaresId.value = "";
      if (android) {
        // window.WebViewJavascriptBridge.callHandler(
        //   "closeTeachMaterial", // native中注册时的key值
        //   null // native中注册方法中的传入的数据
        // );
        // 现在不在该页面向Android去发送隐藏的方法，改为到blank页面去发送
        router.replace({
          path: "/blank",
          query: {
            skinType: 1,
          },
        });
      } else {
        router.push({
          // path: "/home",
          path: "/login",
        });
      }
    };
    const handleChangeType = () => {
      logger.info("查询", typeValue);
      currentPage.value = 1;
      const params = {
        name: searchName.value,
        book_section_id: CoursewaresId.value,
        type: typeValue.value,
      };
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
      logger.info("查询", params);
      // 返回第一页
      // currentPage.value = 1;
    };
    const handleChangeCoursewares = (value) => {
      logger.info("查询", value);
      logger.info("查询", typeValue);
      currentPage.value = 1;
      let params = {
        book_section_id: "",
        name: "",
        type: typeValue.value,
      };
      if (searchName.value) {
        params.name = searchName.value;
      }
      if (value) {
        const num = value.length - 1;
        params.book_section_id = value[num];
        CoursewaresId.value = value[num];
      } else {
        CoursewaresId.value = "";
      }
      getResourceInfo(params);
      mySwiper.value.activeIndex = 1;
    };
    const handleChangeArea = (value) => {
      logger.info("查询区域value", value);
      logger.info("查询区域typeValue", typeValue);
      // currentPage.value = 1;
      // let params = {
      //   book_section_id: "",
      //   name: "",
      //   type: typeValue.value,
      // };
      // if (searchName.value) {
      //   params.name = searchName.value;
      // }
      // if (value) {
      //   const num = value.length - 1;
      //   params.book_section_id = value[num];
      //   CoursewaresId.value = value[num];
      // } else {
      //   CoursewaresId.value = "";
      // }
      // getResourceInfo(params);
    };
    // 处理资源来源数据
    const handleChangeResource = (type) => {
      if (type === "school") {
        return "school";
      } else if (type === "institution") {
        return "ins";
      } else {
        return "official";
      }
    };

    const checkHasFlashPlayer = () => {
      const list = store.state.registedEvents || [];
      try {
        // windows
        if (
          isAndroid.value === true &&
          list.indexOf("start-flash-player") > -1
        ) {
          return true;
        } else if (
          isAndroid.value == false &&
          list.indexOf("openCourseWare") > -1
        ) {
          // 安卓
          return true;
        }
      } catch (error) {
        return false;
      }

      return false;
    };

    // 调用载体端启动flash播放器
    const handleStartFlashPlayer = async (courseWare) => {
      if (checkHasFlashPlayer() == false) {
        ElMessage.error({
          offset: 200,
          message: "该系统版本暂不支持预览本类型，请升级系统",
          type: "error",
        });
        return;
      }

      // windows
      if (isAndroid.value === true) {
        try {
          startFlashPlayer(toRaw(courseWare));
        } catch (err) {
          console.error(err);
          ElMessage.error({
            offset: 200,
            message: "打开课件异常，请重试",
            type: "error",
          });
        }
      } else {
        // android
        if (!window.WebViewJavascriptBridge) {
          console.error("WebViewJavascriptBridge未注册");
          ElMessage.error({
            offset: 200,
            message: "系统异常，请重试",
            type: "error",
          });
          return;
        }
        try {
          // const { eventCode, success } =
          window.WebViewJavascriptBridge.callHandler(
            "openCourseWare",
            JSON.stringify(courseWare)
          );
          // if (eventCode === 0 || success == false) {
          //   throw new Error("调用失败");
          // }
        } catch (err) {
          console.error(err);
          ElMessage.error({
            offset: 200,
            message: "打开课件异常，请重试",
            type: "error",
          });
        }
      }
    };
    //  初始化滑屏
    const init = () => {
      mySwiper.value = document.querySelector(".swiper").swiper;
    };
    const onSwiper = (swiper) => {
      logger.info("onSwiper", swiper);
    };
    const onSlideChange = () => {
      logger.info("当前页码是===>", currentPage.value);
    };
    //  点击按钮切换到下一页
    const next = _.debounce(() => {
      logger.info(
        "点击按钮切换到下一页函数里拿到的当前页数是===>",
        currentPage.value + 1
      );
      if (currentPage.value === totalPage.value) {
        message.value = "已经是最后一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        // console.log("准备翻下一页", TurningPagesDataState.value);
        console.log("a11", mySwiper.value);
        console.log("a21", mySwiper.value.slideNext);
        mySwiper.value.slideNext();
        // currentPage.value++;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  点击按钮切换到上一页
    const previous = _.debounce(() => {
      logger.info(
        "点击按钮切换到上一页函数里拿到的当前页数是===>",
        currentPage.value - 1
      );
      if (currentPage.value === 1) {
        message.value = "已经是第一页";
        ElMessage.warning({
          offset: 200,
          message: message.value,
          type: "warning",
        });
      } else {
        console.log("a1", mySwiper.value);
        console.log("a2", mySwiper.value.slidePrev);
        mySwiper.value.slidePrev();
        // currentPage.value--;
        // teachMaterialeight.length = 0;
        // const startIndex = currentPage.value * 10 - 10;
        // const endIndex = currentPage.value * 10;
        // teachMaterialeight.push(
        //   ...teachMaterialList.slice(startIndex, endIndex)
        // );
      }
    }, 200);
    //  页面切换到上一页
    const onSlidePrevTransitionEnd = () => {
      // if (TurningPagesDataState.value) {
      // 开始加载数据，将翻页功能锁定
      console.log(222222);
      leftBorderFlag.value = false;
      rightBorderFlag.value = false;
      // TurningPagesDataState.value = false;
      const currentActiveIndex = mySwiper.value.activeIndex;
      const nextPage = currentPage.value - 1;
      const nextPageMOD = nextPage % 3;
      const currentPageMOD = currentActiveIndex % 3;
      if (nextPageMOD === currentPageMOD) {
        mySwiper.value.touches.diff = 0;
        logger.info(
          "activeIndex页面切换到上一页函数里拿到的当前页数是===>",
          currentPage.value - 1,
          TurningPagesState.value
        );
        logger.info("activeIndex===>", mySwiper.value.activeIndex);
        currentPage.value--;
        const params = {
          name: searchName.value,
          book_section_id: CoursewaresId.value,
          type: typeValue.value,
        };
        if (currentPage.value !== 1) {
          TurningPagesState.value = true;
          getPrevData(currentPage.value - 1, params);
        } else {
          logger.info("翻页失败1");
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
      }
      // } else {
      //   ElMessage.info({
      //     offset: 200,
      //     message: "网络环境差，数据加载中...",
      //     type: "info",
      //   });
      // }

      // getPrevData(currentPage.value - 1, params);
    };
    //  页面切换到下一页
    const onSlideNextTransitionEnd = () => {
      // console.log("开始翻页", TurningPagesDataState.value);
      console.log("我监听了", leftBorderFlag.value, rightBorderFlag.value);
      leftBorderFlag.value = false;
      rightBorderFlag.value = false;
      // if (TurningPagesDataState.value) {
      console.log("111111");
      if (!firstLoading.value) {
        // 开始加载数据，将翻页功能锁定
        // TurningPagesDataState.value = false;
        const currentActiveIndex = mySwiper.value.activeIndex;
        const nextPage = currentPage.value + 1;
        const nextPageMOD = nextPage % 3;
        const currentPageMOD = currentActiveIndex % 3;
        if (nextPageMOD === currentPageMOD) {
          mySwiper.value.touches.diff = 0;
          logger.info(
            "activeIndex1页面切换到啊啊啊下一页函数里拿到的当前页数是===>",
            currentPage.value + 1,
            TurningPagesState.value
          );
          logger.info("activeIndex===>", mySwiper.value.activeIndex);
          currentPage.value++;
          const params = {
            name: searchName.value,
            book_section_id: CoursewaresId.value,
            type: typeValue.value,
          };
          if (currentPage.value !== totalPage.value) {
            TurningPagesState.value = true;
            getNextData(currentPage.value + 1, params);
          }
        } else {
          logger.info("翻页失败");
          leftBorderFlag.value = true;
          rightBorderFlag.value = true;
        }
        // getNextData(currentPage.value + 1, params);
      }
      // }
      // else {
      //   console.log("我监听了", leftBorderFlag.value, rightBorderFlag.value);
      //   ElMessage.info({
      //     offset: 200,
      //     message: "网络环境差，数据加载中...",
      //     type: "info",
      //   });
      // }
    };
    // 翻页page
    let turnPagesPage = ref();
    let turnPagesParams = ref();
    let turnPagesType = ref();
    //  切换时获取下一页数据的函数
    const getNextData = (page, params) => {
      turnPagesPage.value = page;
      turnPagesParams.value = params;
      turnPagesType.value = "Next";
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: page,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
        logger.info("拿数据时的页面是===>", currentPage.value);
        if ((currentPage.value + 1) % 3 === 1) {
          console.log("ersaa1");
          teachResourceEight.length = 0;
          teachResourceEight.push(...switchTeachMaterialList);
          console.log("ersaa11", teachResourceEight);
        }
        if ((currentPage.value + 1) % 3 === 2) {
          console.log("ersaa2");
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...switchTeachMaterialList);
          console.log("ersaa21", middleTeachResourceEight);
        }
        if ((currentPage.value + 1) % 3 === 0) {
          console.log("ersaa3");
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...switchTeachMaterialList);
          console.log("ersaa31", lastTeachResourceEight);
        }
        TurningPagesState.value = false;
        // // 加载数据完成，将翻页功能打开
        // TurningPagesDataState.value = true;
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
        console.log("ersaa4", TurningPagesState.value);
      });
    };

    //  切换时获取上一页数据的函数
    const getPrevData = (page, params) => {
      turnPagesPage.value = page;
      turnPagesParams.value = params;
      turnPagesType.value = "Prev";
      const searchFunc = getResourceFuncByTabType();
      searchFunc({
        is_primary: 0,
        per_page: 8,
        page: page,
        book_show_modules: 1,
        ...params,
      }).then(async (data) => {
        let finishedData = data;
        if (localResourceState.value) {
          finishedData = await getLocalResourceState(data);
        }
        const switchTeachMaterialList = finishedData.data.items;
        logger.info("切换请求数据在这拿到===>", switchTeachMaterialList);
        logger.info("拿数据时的页面是===>", currentPage.value);
        if ((currentPage.value - 1) % 3 === 1) {
          teachResourceEight.length = 0;
          teachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value - 1) % 3 === 2) {
          middleTeachResourceEight.length = 0;
          middleTeachResourceEight.push(...switchTeachMaterialList);
        }
        if ((currentPage.value - 1) % 3 === 0) {
          lastTeachResourceEight.length = 0;
          lastTeachResourceEight.push(...switchTeachMaterialList);
        }
        TurningPagesState.value = false;
        // 加载数据完成，将翻页功能打开
        // TurningPagesDataState.value = true;
        leftBorderFlag.value = true;
        rightBorderFlag.value = true;
      });
    };
    watch(
      () => currentPage.value,
      () => {
        logger.info(
          "我监听了",
          leftBorderFlag.value,
          rightBorderFlag.value,
          currentPage.value
        );
        if (currentPage.value === 1) {
          leftBorderFlag.value = false;
          rightBorderFlag.value = true;
          logger.info("这里禁用循环", leftBorderFlag.value);
        } else if (currentPage.value === totalPage.value) {
          rightBorderFlag.value = false;
          leftBorderFlag.value = true;
          logger.info("这里禁用循环1", leftBorderFlag.value);
        }
      },
      { immediate: true }
    );
    watch(
      () => mySwiper.value,
      _.debounce(() => {
        console.log("滑动的状态", leftBorderFlag.value, rightBorderFlag.value);
        logger.info("在这看触摸距离startX===>", mySwiper.value.touches.startX);
        logger.info(
          "在这看触摸距离currentX===>",
          mySwiper.value.touches.currentX
        );
        console.log("a3", mySwiper.value);
        console.log("a31", mySwiper.value.slideNext);
        if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX > 0 &&
          mySwiper.value.touches.diff > 0 &&
          currentPage.value === 1
        ) {
          message.value = "已经是第一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        } else if (
          mySwiper.value.touches.currentX - mySwiper.value.touches.startX < 0 &&
          mySwiper.value.touches.diff < 0 &&
          currentPage.value === totalPage.value
        ) {
          message.value = "已经是最后一页";
          ElMessage.warning({
            offset: 200,
            message: message.value,
            type: "warning",
          });
        } else if (
          leftBorderFlag.value === false &&
          rightBorderFlag.value === false
        ) {
          // setTimeout(() => {
          console.log(
            "滑动翻页重试",
            turnPagesPage.value,
            turnPagesParams.value,
            turnPagesType.value,
            currentPage.value,
            totalPage.value
          );
          if (totalPage.value > 1) {
            ElMessage.warning({
              offset: 200,
              message: "数据加载中，请稍后...",
              type: "warning",
            });
          }
        }
      }, 500),
      { deep: true }
    );
    return {
      isHighVersion,
      onSwiper,
      next,
      previous,
      firstLoading,
      onSlidePrevTransitionEnd,
      onSlideNextTransitionEnd,
      getPrevData,
      getNextData,
      onSlideChange,
      mySwiper,
      leftBorderFlag,
      rightBorderFlag,
      noDataMsg,
      middleTeachResourceEight,
      lastTeachResourceEight,
      teacherName,
      teachResourceEight,
      cascaderList,
      currentPage,
      totalPage,
      searchName,
      imgVisible,
      audioVisible,
      videoVisible,
      audioSrc,
      videoSrc,
      imgSrc,
      pptUrl,
      isAndroid,
      loading,
      musicUrl,
      bindIcon,
      // handlePrevious,
      // handleNext,
      handleSearch,
      handleGoToLogin,
      handleCloseShow,
      handleCardClick,
      handleChangeCoursewares,
      ...toRefs(state),
      CascaderRef,
      refreshItem,
      handleCopyOfficial,
      activePanelActive,
      schoolName,
      showResourceRef,
      noData,
      typeOptions,
      typeValue,
      handleChangeType,
      currentTab,
      showIns,
      TabTypeMap,
      handleTabClick,
      handleChangeArea,
      bookSelectFullValue,
      isML,
      init,
      initGetResourceInfo,
      handleLocalResource,
      localResourceState,
    };
  },
});
