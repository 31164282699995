import { createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/img/videoBJ.png'


_pushScopeId("data-v-4caabcaa")
const _hoisted_1 = { class: "bg" }
const _hoisted_2 = { class: "audio-bg" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "video-bg" }
const _hoisted_5 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_picture = _resolveComponent("icon-picture")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_video_control = _resolveComponent("video-control")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createElementVNode("audio", {
        controls: "",
        src: _ctx.src,
        controlsList: "nodownload",
        oncontextmenu: "return false"
      }, null, 8, _hoisted_3),
      _createElementVNode("div", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleShowResource('audio', false)))
      })
    ], 512), [
      [_vShow, _ctx.audioVisible]
    ]),
    _withDirectives(_createElementVNode("div", {
      class: "img-bg",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleShowResource('image', false)))
    }, [
      _createVNode(_component_el_image, {
        src: _ctx.src,
        fit: "scale-down",
        style: {"width":"90%","height":"90%"}
      }, {
        placeholder: _withCtx(() => [
          _createVNode(_component_el_icon, null, {
            default: _withCtx(() => [
              _createVNode(_component_icon_picture)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["src"])
    ], 512), [
      [_vShow, _ctx.imageVisible]
    ]),
    _withDirectives(_createElementVNode("div", _hoisted_4, [
      _createElementVNode("video", {
        ref: "videoRef",
        src: _ctx.src,
        crossorigin: "anonymous",
        oncontextmenu: "return false",
        disablePictureInPicture: "true",
        poster: _imports_0
      }, null, 8, _hoisted_5),
      _createVNode(_component_video_control, {
        status: _ctx.status,
        duration: _ctx.duration,
        currentTime: _ctx.currentTime,
        onPlay: _ctx.onPlay,
        onStop: _ctx.onStop,
        onSetTime: _ctx.onSetTime
      }, null, 8, ["status", "duration", "currentTime", "onPlay", "onStop", "onSetTime"]),
      _createElementVNode("div", {
        class: "close testdv",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.handleShowResource('video', false)))
      })
    ], 512), [
      [_vShow, _ctx.videoVisible]
    ])
  ], 512)), [
    [_vShow, _ctx.imageVisible || _ctx.audioVisible || _ctx.videoVisible]
  ])
}